.muve {
  position: relative;
  z-index: 1;
  background-color: var(--navy);
  border-radius: 10px 10px 0 0;

  min-height: 495px;

  &__wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  &__text-box {
    transform: translateY(-50%);
    width: 100%;
    background-color: var(--blue);
    padding: ac(44px, 10px, 320) ac(125px, 10px, 320);
    max-width: 1280px;
    margin: 0 auto ac(-12px, 0px);
    overflow: hidden;
    border-radius: ac(102px, 50px, 320);
  }

  &__text {
    width: calc(100% + ac(50px, 20px));
    max-width: 1030px;
    height: ac(96px, 40px, 320);

    img {
      object-fit: contain;
    }
  }

  &__decor-box {
    position: absolute;
    width: ac(414px, 180px, 768, 1440);
    height: ac(474px, 250px, 768, 1440);
    bottom: 0;
    right: 0;
    overflow: hidden;
  }

  &__decor {
    position: absolute;
    left: 0;
    bottom: -26px;
    width: ac(530px, 180px, 768, 1440);
    height: ac(494px, 180px, 768, 1440);

    img {
      object-fit: contain;
      object-position: left bottom;
    }
  }

  &__img {
    margin-bottom: -91px;
    flex: 0 0 auto;
    margin-right: ac(100px, 35px, 768, 1440);
    margin-left: ac(-107px, -30px);
    width: ac(616px, 360px, 768, 1440);
    height: ac(490px, 230px);
    border-radius: 0px 500px 500px 0px;
    overflow: hidden;
  }

  &__col {
    max-width: ac(500px, 390px, 768);
    margin-top: 77px;
    color: var(--white);
  }

  &__subtitle {
    margin-top: ac(27px, 13px);
    max-width: 30ch;
    font-size: ac(22px, 17px);
    line-height: 1.2;
    font-weight: 300;
  }

  &__btn-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: ac(44px, 29px);
    margin-right: -20px;
    margin-bottom: -10px;
  }

  &__btn {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @mixin full {
    min-height: 570px;

    &::before {
      height: calc(100% - 60px);
    }

    &__img {
      height: 350px;
      width: 600px;
      margin-right: 190px;
      border-radius: 999px;
      margin-left: 0;
    }

    &__col {
      max-width: 660px;
    }

    &__subtitle {
      font-size: 24px;
      max-width: 35ch;
      line-height: 36px;
    }
  }

  @mixin sm {
    &::before {
      height: calc(100% - 28px);
      border-radius: 0 0 0 15px;
    }

    &__decor {
      bottom: 5px;
      width: ac(333px, 180px, 375, 768);
      height: ac(333px, 179px, 375, 768);
    }

    &__wrap {
      flex-direction: column;
      padding-bottom: ac(60px, 130px, 375, 768);
    }

    &__text-box {
      margin: 0;
    }

    &__img {
      margin-left: 0;
      width: 100%;
      height: ac(300px, 200px, 375, 768);
      margin-top: 0;
      border-radius: 15px;
      margin-bottom: 0;

      img {
        object-position: top;
        border-radius: 15px;
      }
    }

    &__col {
      margin-top: 35px;
      max-width: 100%;
    }
  }

  @mixin xs {
    &__text {
      margin-left: 25px;
      width: calc(100% - 50px);
    }

    &__img {
      img {
        object-position: center;
      }
    }
  }
}
