.benefits {
  margin-top: ac(120px, 60px);
  position: relative;
  background-color: var(--white);

  &__wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__title {
    margin: 0 auto;
    max-width: ac(1280px, 870px, 1200, 1440);
    text-align: center;
    flex-grow: 1;
  }

  &__slider-box {
    margin-top: ac(36px, 30px);
    width: 100%;
    display: flex;
  }

  &__slider {
    width: 100% !important;
    overflow: visible !important;
  }

  &__slide {
    max-width: ac(305px, 265px);
    height: 100%;

    &:nth-child(3n + 2) {
      .benefits__card {
        color: var(--white);
        background-color: var(--blue);
        border-color: var(--blue);
      }

      .benefits__icon {
        width: ac(69px, 59px);

        filter: brightness(0) invert(1);
      }
    }

    &:nth-child(3n + 3) {
      .benefits__card {
        color: var(--white);
        background-color: var(--red);
        border-color: var(--red);
      }

      .benefits__icon {
        filter: brightness(0) invert(1);
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    padding: ac(29px, 19px);
    height: 100%;
    background-color: var(--gray);
    border: 1px solid var(--border);
    color: var(--navy);
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
    perspective: 1000px;
    transform-style: preserve-3d;
    cursor: pointer;

    .btn {
      width: 100%;
      max-width: 100%;
      margin-top: auto;
      border-radius: 8px;
    }

    &:hover {
      .benefits__icon {
        transform: translateZ(50px);
      }

      .benefits__bottom {
        transform: translateZ(40px);
      }
    }
  }

  &__icon {
    width: ac(64px, 54px);
    height: ac(64px, 54px);
    transition: 0.3s ease;

    filter: brightness(0);

    img {
      object-fit: contain;
      transition: 0.3s ease;
    }
  }

  &__bottom {
    margin-bottom: 20px;

    width: calc(100% + 10px);
    margin-top: ac(30px, 18px);
    padding-right: 10px;
    transition: 0.3s ease;

    &>p {
      font-weight: 300;
    }
  }

  &__head {
    font-size: ac(22px, 17px);
    line-height: ac(32px, 27px);
    font-weight: 700;
  }

  &__text {
    margin-top: ac(22px, 14px);
  }

  @mixin full {
    margin-top: 160px;

    &::before {
      height: calc(100% + 569px);
    }

    &__slider-box {
      margin-top: 60px;
    }

    &__slide {
      max-width: 366px;
    }

    &__card {
      padding: 55px 55px 52px;
    }

    &__bottom {
      margin-top: 40px;
    }

    &__title {
      max-width: 1660px;
    }

    &__icon {
      width: 80px;
      height: 80px;
    }

    &__head {
      font-size: 24px;
      line-height: 36px;
    }

    &__text {
      margin-top: 20px;
    }
  }

  @mixin lg {
    .arrow {
      margin-top: 30px;
      order: 3;
      z-index: 2;
    }

    .benefits__wrap {
      justify-content: center;
      gap: 20px;
    }
  }

  @mixin xs {
    &::before {
      border-radius: 15px 15px 0 0;
    }

    &__title {
      max-width: 12ch;
      margin: 0;
      text-align: left;
    }
  }
}
