:root {
  --main: #707070;
  --blue: #53bedb;
  --white: #ffffff;
  --dark-blue: #0e1127;
  --dark-gray: #343434;
  --light-gray: #dce1e4;
}

#popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;
  }
}

.popup {
  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    max-height: 85vh;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--blue) var(--navy);
    border-radius: 10px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--blue);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--navy);
    }

    --default-translate-x-from: -50%;
    --default-translate-x-to: -50%;
    --default-translate-y-from: -50%;
    --default-translate-y-to: -50%;
    --opacity-from: 0;
    --opacity-to: 1;
    background: var(--white);
    position: absolute;
    top: 50%;
    left: 50%;
    width: ac(1000px, 300px);
    padding: 60px ac(45px, 15px) ac(45px, 15px);
    transform: translateY(var(--default-translate-y-to))
      translateX(var(--default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.apply-popup-styles {
      width: ac(900px, 300px);
    }

    &.without-opacity {
      --opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --default-translate-x-from: 0;
      --default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --default-translate-y-from: 0;
      --default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --default-translate-x-from: 0;
      --default-translate-x-to: 0;

      .popup__container {
        max-height: 90vh;
      }
    }

    @media only screen and (max-width: 1025px) {
      width: 95%;
      min-height: auto;

      &:not(.full-height) {
        max-height: 90%;
      }
    }
  }

  &__title {
    margin-bottom: min(max(30px, 3.47vw), 50px);
    font-size: ac(36px, 28px);
    line-height: ac(50px, 38px);
    font-weight: 700;
    font-family: var(--font-main);
  }

  &__btn-close {
    position: absolute;
    right: ac(15px, 5px);
    top: ac(15px, 5px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ac(50px, 46px);
    height: ac(50px, 46px);
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid #000;

    .cross-clip {
      clip-path: unset;
      background-color: unset;
      width: 36px;
      height: 36px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none'%3E%3Cpath fill='%23000' fill-rule='evenodd' d='m18 21.183 7.954 7.954a2.25 2.25 0 1 0 3.183-3.183L21.18 18l7.956-7.954a2.25 2.25 0 1 0-3.183-3.182L18 14.819l-7.955-7.955a2.25 2.25 0 1 0-3.183 3.18L14.82 18l-7.956 7.956a2.251 2.251 0 1 0 3.183 3.18L18 21.183Z' clip-rule='evenodd'/%3E%3C/svg%3E");
      transition: .3s ease;
    }

    &:hover  {
        background-color: var(--blue) !important;
        border-color: var(--blue);

        .cross-clip {
          background-color: transparent !important;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='m18 21.183 7.954 7.954a2.25 2.25 0 1 0 3.183-3.183L21.18 18l7.956-7.954a2.25 2.25 0 1 0-3.183-3.182L18 14.819l-7.955-7.955a2.25 2.25 0 1 0-3.183 3.18L14.82 18l-7.956 7.956a2.251 2.251 0 1 0 3.183 3.18L18 21.183Z' clip-rule='evenodd'/%3E%3C/svg%3E");
        }
    }
  }
}

.old-popup-styles {
  border-radius: 8px;
  border: 2px solid var(--black);
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    margin-bottom: ac(57px, 25px);

    font-size: ac(34px, 24px);
    line-height: ac(42px, 28px);
  }

  &__head {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-right: 74px;
    flex: 0 0 auto;
    margin-bottom: 20px;

    span {
      font-weight: 600;

      &:first-child {
        font-size: ac(18px, 16px);
        margin-bottom: ac(12px, 5px);
      }

      &:not(:first-child) {
        font-weight: 500;
        font-size: ac(18px, 16px);
        line-height: ac(22px, 20px);
        color: var(--blue);
      }
    }
  }

  &__descr {
    margin-top: ac(45px, 20px);
    word-break: break-word;

    p, li {
      font-weight: 300;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &:first-child {
        &>*:first-child {
          margin-top: 0;
        }
      }

      b, strong {
        display: inline-block;
        font-weight: 600;
        margin-bottom: 14px;

        &:first-child {
          margin-top: ac(17px, 10px);
        }
      }
    }

    ol,ul {
      margin-bottom: 10px;
      li {
        color: var(--black);
      }
    }

    ul {
      li {
        &::before {
          background-color: var(--black);
        }
      }
    }

    h2,
    h3,
    h4 {
      &:not(:first-child) {
        margin-top: 0.8em;
      }

      &:not(:last-child) {
        margin-bottom: 0.4em;

      }
    }

    a {
      display: inline-block;
      text-decoration: underline;
    }
  }

  .btn {
    min-width: 159px;
    margin-top: ac(38px, 26px);
    margin-left: auto;
    background-color: var(--blue);
    border-color: var(--blue);

    &:hover {
      color: var(--blue);
      background-color: var(--white);
    }
  }
}

.pc-cv-field input[type="file"] {
  display: none;
}

.sector-popup,
.features-popup {
  &__head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: ac(40px, 20px);
    margin-bottom: ac(50px, 30px);
  }
  &__logo {
    width: 90px;
    height: 90px;

    img {
      object-fit: contain;
    }
  }

  &__title {
    flex: 1 0 250px;
    margin-bottom: 0;
    font-size: ac(34px, 24px);
    line-height: ac(42px, 28px);
  }

}
.sector-popup {
  display: grid;

  &__img {
    margin: -60px ac(-45px, -15px) ac(30px, 20px) auto;
    width: calc(100% - ac(175px, 20px, 320));
    height: ac(366px, 200px, 320);

    border-radius: ac(500px, 100px, 320) 0 0 ac(500px, 100px, 320);
    overflow: hidden;
  }

  .btn {
    min-width: 159px;
    margin-top: ac(38px, 26px);
    margin-left: auto;
    background-color: var(--blue);
    border-color: var(--blue);

    &:hover {
      color: var(--blue);
      background-color: var(--white);
    }
  }
}

.popup-apply {
  margin-top: ac(-15px, 0px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > span {
    margin-bottom: ac(20px, 15px);
    width: 100%;
    font-size: ac(24px, 18px);
    line-height: ac(32px, 26px);
    font-weight: 500;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
&__select {
  width: calc(50% - ac(20px, 10px));
  margin-bottom: ac(25px, 20px);
  min-height: ac(57px, 47px);
  border-bottom: 1px solid var(--black);

  .current {
    display:inline-block;
    font-family: var(--font-main);
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color:  var(--black);
  }
}
  &__input {
    width: calc(50% - ac(20px, 10px));
    margin-bottom: ac(25px, 20px);
    padding: ac(15px, 10px) 0;
    border-bottom: 1px solid var(--gainsboro);
    border-radius: 0;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: var(--black) !important;
    }

    &:focus,
    &:valid {
      border-color: var(--black);
    }

    &,
    &::placeholder {
      font-family: var(--font-main);
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
    }

    &::placeholder {
      color: var(--black-o);
    }
  }

  &__upload-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ac(20px, 15px);
  }

  &__upload-label {
    margin-bottom: 10px;
    font-size: ac(24px, 18px);
    line-height: ac(36px, 22px);
    font-weight: 600;
    color: var(--black);
  }

  &__upload-btn {
    width: calc(50% - ac(20px, 10px));
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(20px, 16px);
    border: 2px solid var(--black);
    transition: 0.3s ease;
    cursor: pointer;

    &:hover {
      color: var(--white);
      background-color: var(--black);
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -20px;

    & > * {
      margin-top: 20px;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    position: relative;

    &__input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
      height: 0;

      &:checked + .checkbox__text {
        &::before {
          background: var(--black);
        }
      }
    }

    &__text {
      display: block;
      position: relative;
      padding-right: 20px;
      padding-left: 40px;
      font-family: var(--font-main);
      font-size: 18px;
      color: var(--black);
      text-transform: unset;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        box-sizing: border-box;
        width: 25px;
        height: 25px;
        border: ac(2px, 1px) solid var(--black);
        background: var(--white);
        transition: all 300ms ease-out;
      }

      a {
        font-weight: 600;
        color: var(--black);
        text-decoration: underline;
        transition: 0.3s ease;

        &:hover {
          color: var(--red);
        }
      }
    }
  }
}

@mixin full {
  .sector-popup__img {
      margin: -52px -76px ac(30px, 20px) auto;
  }

  .popup {
    &__title {
      font-size: 44px;
      line-height: 58px;
    }
    &__block {
      width: 1200px;
      padding: 50px 75px;

      &.apply-popup-styles {
        width: 1000px;
      }
    }
  }

  .info {
    &__item {
      margin-right: 150px;

      span {
        &:first-child {
          font-size: 24px;
          margin-bottom: 15px;
        }

        &:not(:first-child) {
          font-size: 22px;
          line-height: 26px;
        }
      }
    }
  }

  .popup-apply {
    &__select {

      min-height: 79px;

      .current {
        font-size: 20px;
        line-height: 28px;
      }
    }
    &__input {
      padding: 25px 0;

      &,
      &::placeholder {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}

@mixin xs {
  .info {
    .btn {
      max-width: 100%;
      min-width: unset;
    }
  }

  .popup-apply {
    &__upload-btn {
      max-width: 345px;
      width: 100%;
    }

    &__select,
    &__input {
      width: 100%;
    }

    .btn {
      min-width: unset;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .sector-popup .btn {
    margin-right: auto;
  }
}

@media only screen and (max-width: 550px) {
  .popup-center {
    .close-popup {
      font-size: 20px;
      top: 20px;
      right: 15px;
    }
  }
}

@media only screen and (max-width: 420px) {
  .popup {
    &.popup_height_full {
      padding: 40px 20px;
    }
  }
}

@mixin full {
  .popup {
    &__title {
      font-size: 20px;
      line-height: 30px;
    }
  }
}
