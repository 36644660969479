.fields {
  position: relative;

  &__decor {
    position: absolute;
    top: ac(425px, 240px, 768, 1440);
    left: 0;
    width: ac(460px, 200px, 768, 1440);
    height: ac(140px, 61px, 768, 1440);

    img {
      object-fit: contain;
    }
  }

  &__wrap {
    display: flex;
  }

  &__img {
    --margin-left: -80px;

    flex: 0 0 auto;
    margin-right: ac(110px, 30px, 768, 1440);
    margin-left: var(--margin-left);

    width: calc(ac(540px, 300px) - var(--margin-left));
    height: ac(540px, 300px);

    img {
      border-radius: 0 999px 999px 0;
    }
  }

  &__subtitle {
    margin-top: ac(20px, 14px);
    font-size: ac(22px, 17px);
    font-weight: 300;
    line-height: 1.2;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    margin-top: ac(42px, 28px);
  }

  &__col {
    width: calc(50% - ac(10px, 5px, 768, 1440));
  }

  &__item {
    display: flex;
    padding: ac(21px, 11px) 0 ac(18px, 9px);
    border-top: 1px solid var(--gainsboro);
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    text-transform: capitalize;

    &::before {
      content: "";
      flex-shrink: 0;
      transform: translateY(ac(0px, 4px));
      width: ac(24px, 16px);
      height: ac(24px, 16px);
      margin-right: ac(11px, 8px);
      border-radius: 50%;
      background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.18246 4.51258C10.7469 3.92386 12.4565 3.83999 14.0711 4.27274C14.6046 4.41573 15.1529 4.09918 15.2959 3.56573C15.4389 3.03227 15.1223 2.48391 14.5889 2.34093C12.5707 1.79999 10.4337 1.90483 8.47807 2.64073C6.52248 3.37663 4.84652 4.70661 3.68558 6.44389C2.52465 8.18116 1.93706 10.2385 2.00534 12.3268C2.07363 14.4152 2.79436 16.4297 4.0663 18.0874C5.33825 19.7451 7.09751 20.9628 9.097 21.5694C11.0965 22.1759 13.2358 22.1409 15.2144 21.4693C17.1929 20.7977 18.9114 19.5232 20.1285 17.8247C21.3456 16.1263 22 14.0893 22 11.9998C22 11.4475 21.5523 10.9998 21 10.9998C20.4477 10.9998 20 11.4476 20 11.9999C20 13.6714 19.4765 15.301 18.5028 16.6598C17.5292 18.0185 16.1544 19.0382 14.5715 19.5755C12.9886 20.1128 11.2772 20.1407 9.6776 19.6555C8.07801 19.1702 6.6706 18.1961 5.65304 16.8699C4.63549 15.5437 4.0589 13.9321 4.00428 12.2614C3.94965 10.5908 4.41972 8.94493 5.34847 7.55511C6.27721 6.16529 7.61798 5.1013 9.18246 4.51258Z' fill='%2353BEDB'/%3E%3Cpath d='M20.7526 5.65849C21.1163 5.24285 21.0741 4.61108 20.6585 4.24741C20.2428 3.88374 19.6111 3.92587 19.2474 4.34151L12.8584 11.6435L9.60008 9.20006C9.15828 8.86864 8.53147 8.95813 8.20006 9.39992C7.86864 9.84172 7.95813 10.4685 8.39992 10.7999L11.6579 13.2439C12.0616 13.5467 12.5649 13.6857 13.0667 13.6331C13.5684 13.5804 14.0316 13.3402 14.3636 12.9605L20.7526 5.65849Z' fill='%2353BEDB'/%3E%3C/svg%3E%0A")
        center / contain no-repeat;
    }

    &:last-child {
      border-bottom: 1px solid var(--gainsboro);
    }
  }

  &__btn {
    margin-top: ac(39px, 31px);
    min-width: 152px;
  }

  @mixin full {
    &__decor {
      top: 515px;
      width: 550px;
      height: 170px;
    }

    &__img {
      width: 600px;
      height: 600px;
      margin-right: 100px;
      margin-left: 0;

      img {
        border-radius: 999px;
      }
    }

    &__list {
      margin-top: 50px;
    }

    &__col {
      width: calc(50% - 20px);
    }

    &__subtitle {
      margin-top: 25px;
      font-size: 24px;
      line-height: 36px;
    }

    &__item {
      padding: 24px 0 21px;
      font-size: 18px;
      line-height: 28px;

      &::before {
        width: 18px;
        height: 18px;
        margin-top: 6px;
        margin-right: 20px;
      }
    }

    &__btn {
      margin-top: 45px;
      min-width: 185px;
    }
  }

  @mixin sm {
    margin-top: -76px;

    &__decor {
      top: 300px;
      width: 300px;
      height: 91px;
    }

    &__wrap {
      flex-direction: column;
    }

    &__img {
      width: 100%;
      height: 345px;
      margin-top: 0;
      margin-right: 0;

      img {
        object-position: top;
      }
    }

    &__text {
      margin-top: 45px;
    }
  }

  @mixin xs {
    h2 {
      max-width: 10ch;
    }

    &__img {
      &,
      img {
        margin-left: 0;
        border-radius: 15px;
      }
    }

    &__list {
      flex-direction: column;
    }

    &__col {
      width: 100%;

      &:first-child {
        .fields__item {
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
