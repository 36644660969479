html {
  font-size: ac(16px, 15px);
  line-height: ac(26px, 25px);

  scrollbar-width: thin;
  scrollbar-color: var(--blue) var(--navy);
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: var(--blue);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--navy);
  }
}

body {
  min-width: 320px;
  margin: 0;
  font-family: var(--font-main);
  color: var(--navy);
  background-color: var(--white);

  &.no-scroll {
    overflow: hidden;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: calc(1280px + 2 * ac(35px, 15px, 768, 1440));
  margin: 0 auto;
  padding: 0 ac(35px, 15px, 768, 1440);
}

/* commons styles */
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* end commons styles */

/* swiper */
.swiper-wrapper {
  left: 0 !important;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  border: 2px solid var(--blue);
  background-color: var(--blue);
  transition: 0.3s ease;
  border-radius: 100%;
  z-index: 1;

  span {
    font-size: 19px;
    color: var(--white);
    transition: 0.3s ease;
  }

  &.swiper-button-lock {
    display: none !important;
  }

  &.swiper-button-disabled {
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--white);

    span {
      color: var(--blue);
    }

    &.swiper-button-disabled {
      cursor: not-allowed;
      border: 2px solid var(--blue);
      background-color: var(--blue);

      span {
        color: var(--white);
      }
    }
  }
}

/* end swiper */
.img-box {

  padding-top: ac(367px, 300px);
  margin-top: ac(-249px, -200px);
  position: relative;
  z-index: 1;

  &__img {
    margin: 0 auto;
    width: 100%;
    height: ac(540px, 160px, 320);
    border-radius: 500px;
    overflow: hidden;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 274px;
  height: 60px;
  font-size: 18px;
  line-height: 2px;
  font-weight: 500;
  color: var(--white);
  background-color: var(--navy);
  border: 2px solid var(--navy);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    background-color: var(--white);
    color: var(--navy);
  }

  &--sm {
    min-width: 159px;
  }
}
.nice-select {
  display: flex;
  align-items: center;
  width: ac(320px, 220px, 1024, 1440);
  height: 47px;
  padding-left: 0px;
  padding-right: 20px;
  border: none;
  border-bottom: 1px solid var(--gainsboro);
  background-color: transparent;
  border-radius: 0;
  font-family: var(--font-main);
  font-size: ac(16px, 15px);
  font-weight: 400;
  line-height: ac(26px, 25px);
  color: var(--black-o);
  transition: 0.3s ease;

  &::after {
    margin-top: ac(-4px, -5px);
    right: 2px;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid var(--black);
    border-right: 1px solid var(--black);
    transition: 0.3s ease;
  }

  &.open,
  &:hover {
    .current {
      color: var(--black);
    }

    &,
    &::after {
      border-color: var(--black);
    }
  }

  .popup__title {
    font-size: inherit;
  }

  .current {
    max-width: 100%;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    box-orient: vertical;
    transition: 0.3s ease;
  }
  .list li,
  .current {
    font-family: var(--font-main);
  }

  .list {
    width: 100%;
    box-shadow: inset 0 0 0 1px var(--black);
    background: var(--white);
    max-height: 160px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0);
      border: 0px solid rgba(0, 0, 0, 0);
    }

    .simplebar-wrapper {
      height: 100%;
    }

    .simplebar-content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .simplebar-scrollbar {
      &::before {
        width: 5px;
        background-color: var(--black);
        opacity: 1;
      }
    }

    li {
      color: var(--black);
      white-space: normal;
      transition: 0.3s ease;

      &::before {
        display: none;
      }

      &:first-child {
        display: none;
      }

      &.selected,
      &.focus,
      &:hover {
        background-color: var(--black-o) !important;
      }
    }
  }
}

.page404 {
  position: relative;
  background-color: var(--red);

  &__wrap {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    z-index: 2;
  }

  h1 {
    margin-bottom: 0.3em;
    color: var(--black);

    span {
      color: var(--white);
    }
  }

  p {
    font-size: ac(22px, 18px);
    line-height: 22px;
    margin-bottom: 2em;
    color: var(--black);
  }

  .btn {
    min-width: 200px;
  }
}

.text-box {
  padding: ac(120px, 60px) 0 ac(100px, 50px);
  color: var(--black);

  &__table {
    display: grid;
    grid-template-columns: max-content auto;
    & > * {
      margin-top: 5px;
      &:nth-child(odd) {
        margin-right: ac(60px, 20px);
      }
    }
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:first-child) {
      margin-top: 1.2em;
      margin-bottom: 0.4em;
    }
  }

  h1 {
    margin-bottom: 0.8em;
  }

  p,
  ul,
  ol {
    &:not(:last-child) {
      margin-bottom: 1em;
    }
    li {
      color: var(--black);
    }
  }

  ol {
    li {
      &::before {
        color: var(--black);
      }
    }
  }
  ul {
    li {
      &::before {
        background-color: var(--black);
      }
    }
  }

  a {
    text-decoration: underline;
  }
}

.lines {
  &--first {
    .lines {
      &__item {
        width: 100%;
        height: 18px;
        animation: lines-down 1s 0s linear;
        background-color: var(--blue);

        &:nth-child(2) {
          height: 16px;
          margin-top: 3px;
        }

        &:nth-child(3) {
          height: 15px;
          margin-top: 4px;
        }
        &:nth-child(4) {
          height: 14px;
          margin-top: 6px;
        }

        &:nth-child(5) {
          height: 13px;
          margin-top: 7px;
        }

        &:nth-child(6) {
          height: 11px;
          margin-top: 9px;
        }

        &:nth-child(7) {
          height: 10px;
          margin-top: 10px;
        }

        &:nth-child(8) {
          height: 9px;
          margin-top: 11px;
        }

        &:nth-child(9) {
          height: 7px;
          margin-top: 12px;
        }

        &:nth-child(10) {
          height: 6px;
          margin-top: 14px;
        }

        &:nth-child(11) {
          height: 5px;
          margin-top: 15px;
        }
      }
    }
  }
}

.grecaptcha-badge {
  z-index: 100;
  bottom: 80px !important;
}
@keyframes lines-down {
  0% {
    transform: scale(1, 2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.bold-gif {
  width: 150px;
  height: 30px;
  transition: 0.3s ease;
  display: flex;
  img {
    object-fit: contain;
  }
  &:hover {
    transform: scale(1.15);
  }
}

.cc-nb-buttons-container button,
.cc-cp-foot-save,
.cc-nb-text,
.cc-nb-title,
.cc-pc-head-title-text,
.cc-pc-head-title-headline,
.cc-cp-foot-save,
.cc-cp-foot-byline,
.cc-cp-body-tabs-item-link,
.cc-cp-body-content-entry > *,
.cc-custom-checkbox > label {
  font-family: var(--font-main) !important;
}
.termsfeed-com---pc-dialog {
  .cc-cp-body-tabs {
    background: var(--white) !important;
  }

  .cc-pc-head-close {
    color: var(--white) !important;
  }

  .cc-pc-head-close {
    border: 0 !important;

    &:hover {
      color: var(--red) !important;
    }
  }

  .cc-cp-body-tabs-item {
    background: var(--blue) !important;
    user-select: none;

    &::before {
      display: none !important;
    }

    button {
      color: var(--white) !important;
    }
  }

  .cc-cp-body-tabs-item[active="true"] {
    background: var(--red) !important;

    button {
      color: var(--white) !important;
      box-shadow: unset !important;
    }
  }

  .cc-cp-foot,
  .cc-pc-head {
    background: var(--blue) !important;
  }

  .cc-cp-body-content {
    background: var(--white) !important;
  }

  .cc-cp-foot-byline,
  .cc-pc-head-title-headline {
    color: var(--white) !important;

    a {
      color: var(--white) !important;
      text-decoration: underline;

      &:hover {
        color: var(--red) !important;
      }
    }
  }

  .cc-cp-body-content-entry-text,
  .cc-cp-body-content-entry-title,
  .cc-custom-checkbox label {
    &,
    & a {
      color: var(--black) !important;
    }
  }

  .is-inactive,
  .cc-pc-head-title-text {
    color: var(--white) !important;

    a {
      text-decoration: underline;
      color: var(--white) !important;

      &:hover {
        text-decoration: underline;
        color: var(--red) !important;
      }
    }
  }

  @media only screen and (max-width: 551px) {
    .cc-cp-foot {
      flex-direction: column;
      padding-bottom: 20px;
    }
  }

  .cc-cp-foot-save {
    border: 2px solid var(--black) !important;
    background-color: var(--black) !important;
    border-radius: 0px !important;
    line-height: 38px !important;
    margin-bottom: 0 !important;
    text-align: center;
    padding: 0 20px !important;
    transition: 0.3s ease;
    color: var(--white) !important;
    text-transform: uppercase;

    &:hover {
      color: var(--black) !important;
      background-color: var(--white) !important;
    }
  }
}
@media only screen and (max-width: 428px) {
  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    justify-content: space-between !important;
  }
}
.termsfeed-com---palette-dark.termsfeed-com---nb {
  background: var(--red) !important;
  border: 1px solid var(--white);
  border-radius: 8px 0 0 0;

  .cc-nb-main-container {
    padding: ac(45px, 25px) ac(60px, 25px) ac(30px, 25px) !important;
  }

  .cc-nb-title {
    color: var(--black) !important;
  }

  .cc-nb-text {
    color: var(--black) !important;
    margin-bottom: 0;
  }

  .cc-nb-buttons-container {
    button {
      margin-top: 13px !important;
      border: 2px solid var(--black) !important;
      background-color: var(--black) !important;
      border-radius: 0px !important;
      line-height: 38px !important;
      margin-bottom: 0 !important;
      text-align: center;
      padding: 0 20px !important;
      transition: 0.3s ease;
      color: var(--white) !important;
      text-transform: uppercase;

      &:hover {
        color: var(--black) !important;
        background-color: var(--white) !important;
      }

      &.cc-nb-changep {
        border-color: var(--blue) !important;
        background-color: var(--blue) !important;
        color: var(--white) !important;

        &:hover {
          background-color: var(--white) !important;
          color: var(--blue) !important;
        }
      }
    }
  }
}

@mixin full {
  html {
    font-size: 18px;
    line-height: 28px;
  }

  .container {
    max-width: 1730px;
  }

  .nice-select {
    width: 400px;
    height: 53px;
    font-size: 18px;
    line-height: 28px;
  }

  .img-box {
    .container {
      max-width: 1660px;
    }
    &__img {
      height: 720px;
    }
  }

  .text-box {
    padding: 160px 0 120px;
  }

  .page404 {
    p {
      font-size: 24px;
    }

    .btn {
      min-width: 220px;
    }
  }
}

@mixin sm {
  .img-box {
    &__img {
      width: 90%;
    }
  }
}

@mixin xs {
  .lines {
    &--first {
      margin-top: -7px;
    }
  }

  .arrow {
    width: 40px;
    height: 40px;

    span {
      font-size: 16px;
    }
  }

  .btn {
    width: 100%;
    max-width: 345px;
    height: 56px;
    font-size: 14px;

    &--arrow {
      width: 40px;
      height: 40px;
    }

    &--sm {
      max-width: 120px;
      min-width: 120px;
    }
  }

  .img-box {
    &__img {
      width: 100%;
      border-radius: 15px;
    }
  }

  .nice-select {
    font-size: 15px;
    line-height: 25px;
    height: 40px;
  }

  .page404 {
    .btn {
      min-width: 180px;
    }
  }

  .bold-gif {
    margin: 25px auto 0;
  }
}

@mixin xxs {
  html {
    font-size: 14px;
    line-height: 24px;
  }
}

.bg-gradient {
  background: linear-gradient(
    180deg,
    #efefef 66.69%,
    rgba(239, 239, 239, 0) 100%
  );
}
