.care-type {
  padding-top: ac(206px, 40px);
}

.care-type__tab-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  margin-bottom: ac(63px, 25px);

  @mixin media 901 {
    flex-direction: column;
    gap: 20px;

    margin-bottom: 10px;
  }
}

.care-type-slider__nav {
  display: flex;
  align-items: center;
  gap: ac(16px, 10px);

  margin-left: auto;
}

.care-type__tabs {
  display: flex;

  li {
    padding-left: 0;
  }
}

.care-type__tab {
  position: relative;

  &:not(:last-child) {
    z-index: 1;
    margin-right: ac(-85px, -50px);

    button {
      box-shadow: ac(16px, 10px) 0 var(--white);
    }
  }

  &:not(:first-child) {
    button {
      padding-left: ac(131px, 70px);
    }
  }

  &.active {
    button {
      background: var(--red);
      color: var(--white);

      &:hover {
        color: var(--navy);
      }
    }
  }

  button {
    position: relative;

    padding: ac(18px, 14px) ac(30px, 15px);
    border-radius: 999px;

    color: var(--blue);
    background: var(--gray);

    font-size: ac(24px, 16px);
    font-weight: 500;

    transition: all ease 250ms;

    &:hover {
      color: var(--red);
    }
  }
}

.care-type__tab-content {
  display: flex;
  gap: ac(30px, 15px);

  @mixin media 769 {
    flex-direction: column-reverse;
  }
}

.care-type__tab-text-wrap {
  display: flex;
  flex-direction: column;
  gap: ac(26px, 16px);

  width: 23%;

  @mixin media 769 {
    width: 100%;
  }
}

.care-type__tab-text {
  font-size: ac(16px, 14px);
  font-weight: 300;
  line-height: 2;

  @mixin media 769 {
    text-align: center;
  }
}

.care-type__tab-text-big {
  font-size: ac(24px, 18px);
  font-weight: 500;
  line-height: 1.2;

  @mixin media 769 {
    text-align: center;
  }
}

.cary-type-card {
  padding: ac(20px, 10px);
  border-radius: ac(12px, 10px);
  height: 100%;
  background: var(--gray);
}

.cary-type-card__title {
  margin-bottom: ac(20px, 12px);

  color: var(--blue);

  font-size: ac(24px, 18px);
  font-weight: 500;
  line-height: 1.2;
}

.cary-type-card__scroll {
  width: calc(100% + ac(10px, 5px));
  padding-right: 10px;
  max-height: calc(ac(28px, 22px) * 10);

  .simplebar-track.simplebar-vertical {
    width: 6px;
    background-color: var(--black-o);
  }
  .simplebar-scrollbar:before {
    top: 0 !important;
    left: 0;
    right: 0;
    bottom: 0 !important;
      opacity: 1;
      border-radius: 0;
      background-color: var(--navy);
  }
}

.cary-type-card__description {
  * {
    font-size: 16px;
    font-weight: 300;
    line-height: ac(28px, 22px);
  }
}

.care-type-tab-slider {
  overflow: hidden;

  width: 75%;

  @mixin media 769 {
    width: 100%;
  }

  .swiper-slide {
    height: auto;
  }
}
