.banner {
  position: relative;
  z-index: 2;

  &__stripe {
    position: absolute;
    left: 50%;
    bottom: ac(-410px, -270px);
    transform: translateX(-50%) rotate(6.7deg);

    .js-marquee {
      padding: 30px 0;

      ul {
        display: flex;
        align-items: center;

        li {
          padding: 0 60px;

          font-size: 32px;
          font-weight: 500;
          line-height: 1.2;

          &::before {
            left: 0;
            top: 50%;
            translate: 0 -50%;

            width: 16px;
            height: 16px;
          }
        }
      }
    }

    width: ac(2481px, 1157px);
    overflow: hidden;
    background: var(--blue);

    font-size: ac(22px, 13px);
    line-height: ac(75px, 45px);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;

    z-index: 2;

    &--sec {
      bottom: ac(-400px, -250px);
      transform: translateX(-52.7%) rotate(-6.12deg);
      background: var(--gray);

      ul > li {
        color: var(--navy);

        &::before {
          background: var(--red);
        }
      }
    }
  }

  &__wrap {
    position: absolute;
    top: ac(10px, 39px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* &__btn-box {
    display: flex;
    margin-bottom: ac(-65px, -45px, 768, 1440);
    padding: ac(35px, 15px, 768, 1440);
    z-index: 1;

    .btn {
      &:first-child {
        margin-right: 20px;
      }
    }
  } */

  &__img {
    width: 100%;
    height: ac(540px, 217px);
    margin-bottom: ac(-87px, -45px, 768, 1440);
    border-radius: 999px;
    overflow: hidden;
  }

  @mixin full {
    &__wrap {
      max-width: 1660px;
      top: 60px;
    }

    &__img {
      height: 650px;
    }

    &__stripe {
      width: 4000px;
      line-height: 90px;
      font-size: 24px;
      bottom: -415px;
    }

    .banner__stripe--sec {
      bottom: -420px;
      transform: translateX(-50%) rotate(-2deg);
    }
  }

  @mixin sm {
    &__stripe {
      transform: translateX(-50%) rotate(10deg);
      letter-spacing: 0.6px;
    }

    .banner__stripe--sec {
      transform: translateX(-50.8%) rotate(-6deg);
    }

    &__wrap {
      align-items: flex-start;
    }

    &__img {
      margin-bottom: -110px;
    }

    /* &__btn-box {
      flex-direction: column;
      margin-bottom: -41px;

      .btn {
        &:first-child {
          margin-right: 0px;
          margin-bottom: 10px;
        }
      }
    } */
  }

  @mixin xs {
    &__btn-box {
      width: 100%;
    }

    &__img {
      border-radius: 15px;
    }
  }
}
