.register {
  position: relative;
  margin-top: ac(120px, 77px);

  &__wrap {
    position: relative;
    z-index: 2;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--navy);
    padding: ac(150px, 60px) ac(60px, 30px);
    border-radius: 25px;
  }

  &__bg-logo {
    position: absolute;
    left: 13.4%;
    top: 0;

    object-fit: contain;
    width: fit-content;
    height: 108.6%;

    opacity: 0.6;

    @mixin media 769 {
      left: 50%;
      translate: -50% 0;
    }
  }

  &__title {
    position: relative;
    z-index: 1;
    flex: 0 0 auto;
    font-size: ac(66px, 36px);
    line-height: 0.9;
    margin-right: 20px;
    color: var(--white);
  }

  &__btn-box {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-right: -20px;
    margin-top: -13px;
  }

  &__btn {
    margin-right: 20px;
    margin-top: 13px;
    border-color: var(--blue);
    background: var(--blue);

    &:hover {
      color: var(--blue);
    }
  }

  &__decor {
    width: 100%;
    height: 234px;
    margin-top: ac(-18px, -140px);
  }

  @mixin full {
    /* margin-top: 150px; */

    &__wrap {
      /* padding: 150px 100px 20px; */
    }

    &__title {
      max-width: 650px;
      font-size: 72px;
      /* line-height: 1em; */
    }

    &__decor {
      height: 250px;
    }
  }

  @mixin sm {
    &__title {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 27px;
    }

    &__wrap {
      flex-direction: column;
    }

    &__btn-box {
      justify-content: center;
      z-index: 1;
    }
  }

  @mixin xs {
    &__wrap {
      border-radius: 15px;
    }
  }

  @mixin xxs {
    &__title {
      font-size: 34px;
      line-height: 1em;
    }
  }
}
