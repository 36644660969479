.callback {
  position: relative;
  margin-top: ac(-160px, 30px, 1280);
  padding-top: ac(509px, 200px);

  &__mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(11, 3, 49, 0) 30%,
      rgba(5, 0, 28, 1) 100%
    );
  }

  &__decor {
    position: absolute;
    left: 0;
    top: 0;

    width: 367px;
    height: 360px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left top;
    }

    & + .callback__decor {
      width: 687px;
      height: 608px;
      right: 0;
      left: unset;

      img {
        object-position: right top;
      }
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 30px);
    border-radius: 0 0 25px 25px;
    overflow: hidden;
    background-image: url("../images/callback-bg-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    img {
      display: none;
    }

    &::before {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--black);
      opacity: 0.15;
    }
  }

  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    text-align: center;
    font-size: ac(66px, 36px);
    line-height: 0.9;
    color: var(--white);
  }

  &__form {
    display: flex;
    margin-top: ac(45px, 14px);
    margin-bottom: -30px;

    .title {
      color: var(--white);
      background-color: var(--black);
      padding: 15px 45px;
      text-align: center;
    }
  }

  &__input {
    width: ac(413px, 285px, 568, 1440);
    margin-right: 12px;
    position: relative;

    span {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      z-index: -1;
    }

    input {
      width: 100%;
      height: 60px;
      padding: 0 ac(40px, 30px);
      background-color: var(--seashell);
      border-radius: 8px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: var(--black) !important;
      }

      &,
      &::placeholder {
        font-family: var(--font-main);
        font-size: ac(16px, 15px);
        line-height: ac(26px, 25px);
        font-weight: 400;
      }
    }
  }

  &__select {
    width: ac(413px, 201px, 568, 1440);
    height: 60px;
    padding: 0 ac(40px, 30px);
    margin-right: 10px;
    border-radius: 8px;
    background-color: var(--seashell);
    border-bottom: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);

    &::after {
      right: 30px;
    }
  }

  &__btn {
    min-width: 244px;
    background-color: var(--blue);
    border-color: var(--blue);
    border-radius: 8px;

    &:hover {
      background-color: var(--white);
      color: var(--navy);
    }
  }

  @mixin full {
    &__title {
      font-size: 86px;
      line-height: 86px;
    }

    &__form {
      margin-top: 65px;
    }

    &__select {
      width: 400px;
      height: 60px;
      padding: 0 55px;
      margin-right: 25px;

      &::after {
        right: 40px;
      }
    }

    &__input {
      width: 500px;

      input {
        height: 60px;
        padding: 0 55px;
        margin-right: 25px;
      }

      &,
      &::placeholder {
        font-size: 17px;
        line-height: 28px;
      }
    }

    &__btn {
      min-width: 274px;
    }
  }

  @mixin xl {
    &__bg {
      background-attachment: unset;
    }
  }

  @mixin md {
    &__form {
      width: 90%;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__select,
    &__input {
      margin-right: 0;
      width: calc(50% - 10px);
    }

    &__btn {
      margin-top: 20px;
      margin-left: auto;
    }
  }

  @mixin xs {
    &__bg {
      height: calc(100% - 95px);
      border-radius: 0 0 15px 15px;
    }

    &__form {
      width: 100%;
      align-items: center;
      flex-direction: column;
      min-height: 125px;
    }

    &__decor {
      top: 10px;
      height: 87px;

      img {
        display: none;

        &:nth-child(2) {
          display: block;
        }
      }
    }

    &__select,
    &__input {
      width: 100%;
      margin-bottom: 13px;
    }

    &__select,
    &__input input {
      height: 56px;
    }

    &__btn {
      margin-top: 0;
      margin-right: auto;
    }
  }
}
