.sectors {
    position: relative;
    margin-top: ac(123px, 93px);
    z-index: 2;

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @mixin media 769 {
            justify-content: center;
            gap: 20px;
        }
    }

    &__slider-box {
        margin-top: ac(62px, 24px);
        width: 100%;
        display: flex;
    }

    &__slider {
        width: 100% !important;
        overflow: visible !important;
        max-width: 1030px;
        margin-left: 0;
    }

    &__slide {
        padding-bottom: 33px;

        &:nth-child(3n + 1) {
            .sectors__card {

                .jobs__head {
                    p {
                        &:first-child {
                            color: var(--blue);
                        }
                    }
                }

                &:hover {
                    color: var(--blue);
                }
            }
        }

        &:nth-child(3n + 2) {
            .sectors__card {
                background-color: var(--blue);
                border-color: var(--blue);
                color: var(--white);

                &:hover {
                    color: var(--navy);
                }
            }
        }

        &:nth-child(3n + 3) {
            .sectors__card {
                background-color: var(--red);
                color: var(--white);
                border-color: var(--red);

                &:hover {
                    color: var(--navy);
                }
            }
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        padding: ac(42px, 25px) ac(55px, 27px);
        border-radius: 8px;
        border: 1px solid rgba(11, 3, 49, 0.6);
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.16);
        background-color: #EFEFEF;

        transition: 0.3s ease;
        cursor: pointer;

        .btn {
            max-width: 100%;
            margin-top: auto;
            width: 100%;
            border-radius: 8px;
        }

        &:hover {
            color: var(--white);
        }
    }

    &__head {
        display: flex;
        align-items: flex-end;
        position: relative;
        width: calc(100% + ac(55px, 27px) + ac(55px, 27px));
        margin-left: ac(-55px, -27px);
        margin-top: ac(-42px, -25px);

        padding: 0px ac(55px, 27px);
        border-radius: 7px 7px 0 0;
        min-height: 146px;
        overflow: hidden;
        z-index: 1;
    }

    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: inset 0px 4px 4px 100px rgba(0, 0, 0, 0.25);

        }
    }

    &__name {
        font-size: 36px;
        line-height: 44px;
        font-weight: 600;
        color: var(--white);
    }

    &__descr {
        margin-top: ac(46px, 15px);
        margin-bottom: ac(61px, 27px);
        font-weight: 300;
    }

    @mixin full {
        margin-top: 170px;
        &__slider {
            max-width: 1230px;
        }
        &__card {
            padding: 62px 73px;
        }

        &__head {
            width: calc(100% + 73px + 73px);
            margin-left: -73px;
            margin-top: -62px;

            padding: 0px 73px;
            min-height: 180px;
        }

        &__descr {
            margin-top: 28px;
            margin-bottom: 50px;
        }
    }

    @mixin sm {
        .arrow {
            order: 3;
        }

        &__slider-box {
            margin-top: 0;
        }
    }
}
