@define-mixin full {
  @media only screen and (min-width: 1920px) {
    @mixin-content;
  }
}

@define-mixin xl {
  @media only screen and (max-width: 1365px) {
    @mixin-content;
  }
}

@define-mixin lg {
  @media only screen and (max-width: 1279px) {
    @mixin-content;
  }
}

@define-mixin md {
  @media only screen and (max-width: 1023px) {
    @mixin-content;
  }
}

@define-mixin sm {
  @media only screen and (max-width: 767px) {
    @mixin-content;
  }
}

@define-mixin xs {
  @media only screen and (max-width: 568px) {
    @mixin-content;
  }
}

@define-mixin xxs {
  @media only screen and (max-width: 374px) {
    @mixin-content;
  }
}
