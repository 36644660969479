h1,
h2 {
  font-weight: 600;
  text-transform: uppercase;
}

h1 {
  font-size: ac(66px, 46px);
  line-height: 1em;
}

h2 {
  font-size: ac(46px, 36px);
  line-height: ac(60px, 40px);

  &.blue {
    color: var(--blue);
  }
}

h3 {
  font-size: ac(26px, 24px);
  line-height: ac(31px, 32px);
  font-weight: 600;
  font-family: var(--font-main);
}

h4 {
  font-size: ac(28px, 20px);
  line-height: ac(45px, 32px);
  font-weight: 600;
  font-family: var(--font-main);
}

h5 {
  font-size: ac(26px, 18px);
  line-height: ac(42px, 28px);
  font-weight: 600;
}

h6 {
  font-size: ac(24px, 17px);
  line-height: ac(36px, 26px);
  font-weight: 600;
}

p {
  font-family: var(--font-main);
}

a {
  display: inline-block;
  font-weight: 400;
  color: var(--black);
  text-decoration: none;
  font-family: var(--font-main);

  transition: 0.3s ease;

  &:hover {
    color: var(--red);
  }
}

ul {
  margin-top: 7px;

  li {
    position: relative;
    color: var(--white);
    padding-left: 25px;
    transition: 0.3s ease;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 10px;
      top: 10px;
      width: 4px;
      height: 4px;
      background-color: var(--white);
      border-radius: 50%;
      transition: 0.3s ease;
    }
  }
}

ol {
  counter-reset: item;

  li {
    position: relative;
    padding-left: 20px;
    counter-increment: item;

    &:not(:last-child) {
      margin-bottom: 0.2em;
    }

    &::before {
      content: counter(item) ".";
      position: absolute;
      font-weight: 600;
      left: 0;
      color: var(--black);
    }
  }
}

/* full = (Max-Min) + Max */

@mixin full {
  h1 {
    font-size: 86px;
  }

  h2 {
    font-size: 56px;
    line-height: 70px;
  }

  h3 {
    font-size: 44px;
    line-height: 58px;
  }

  h4 {
    font-size: 30px;
  }

  h5 {
    font-size: 28px;
  }

  h6 {
    font-size: 26px;
  }

  ul {
    margin-top: 12px;

    li {
      padding-left: 18px;

      &::before {
        top: 11px;
        width: 6px;
        height: 6px;
      }
    }
  }
}

/* xxs = Min - (Max-Min) / 2 */

@mixin xxs {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 31px;
    line-height: 35px;
  }

  h3 {
    font-size: 20px;
    line-height: 30px;
  }
}
