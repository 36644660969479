.first {
  padding: ac(120px, 60px) 0 ac(192px, 80px);

  background: linear-gradient(180deg, #ffffff 31.49%, #efefef 100%);

  &__wrap {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    transition: 0.5s ease;
  }

  &__title {
    position: relative;
    flex: 0 0 auto;
    max-width: 11ch;
    transition: 0.5s ease;

    line-height: 1.2;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-57%, -53%);
      width: ac(367px, 200px);
      height: ac(392px, 200px);
      background: url("data:image/svg+xml,%3Csvg width='368' height='392' viewBox='0 0 368 392' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.5'%3E%3Cpath d='M173.98 0.11868C175.757 0.31648 177.574 0.51428 179.352 0.75164C188.319 1.89888 196.732 4.70764 204.632 9.0988C218.063 16.5756 227.859 27.4151 234.653 41.1424C242.237 56.4125 250.019 71.5245 257.722 86.7155C264.516 100.087 271.271 113.458 278.065 126.829C284.938 140.398 291.811 153.967 298.684 167.497C305.4 180.75 312.154 194.042 318.869 207.294C325.743 220.863 332.616 234.432 339.528 247.962C346.441 261.571 353.354 275.14 360.187 288.748C364.138 296.581 366.429 304.928 367.14 313.711C368.641 332.62 363.664 349.631 351.971 364.506C341.583 377.679 328.034 386.026 311.68 389.824C305.518 391.248 299.277 391.842 292.957 391.525C277.551 390.734 263.726 385.631 251.599 376.057C242.435 368.857 235.838 359.679 230.703 349.275C223.119 334.005 215.298 318.814 207.595 303.583C203.092 294.722 198.589 285.861 194.125 276.999C184.961 258.92 175.797 240.841 166.632 222.802C162.248 214.138 157.824 205.475 153.439 196.811C146.487 183.084 139.535 169.356 132.543 155.629C125.828 142.376 119.113 129.163 112.398 115.911C109.632 110.451 106.67 105.111 104.26 99.4934C101.574 93.2429 99.8362 86.7551 99.0857 79.9507C97.1502 62.5839 100.942 46.5226 110.502 31.9249C117.059 21.9162 125.67 14.1625 136.217 8.5054C143.524 4.58896 151.267 2.01756 159.443 0.75164C161.181 0.47472 162.998 0.3956 164.736 0.1978C165.131 0.15824 165.526 0.27692 165.882 0C168.608 0.11868 171.294 0.11868 173.98 0.11868Z' fill='%2353BDDA'/%3E%3Cpath d='M71.5142 391.604C31.1441 391.604 -0.851731 358.493 0.0172912 318.537C0.886314 279.808 32.2501 248.397 71.6722 248.595C111.608 248.753 142.893 280.876 142.814 320.159C142.735 361.064 109.672 391.644 71.5142 391.604Z' fill='%23EF4F23'/%3E%3C/g%3E%3C/svg%3E%0A")
        center / contain no-repeat;
    }

    span {
      color: var(--blue);
      display: inline-block;
      position: relative;

      &:nth-child(1) {
        &::before,
        &::after {
          animation-delay: 0.3s;
        }
      }

      &:nth-child(2) {
        &::before,
        &::after {
          animation-delay: 0.4s;
        }
      }

      &:nth-child(3) {
        &::before,
        &::after {
          animation-delay: 0.5s;
        }
      }

      &:nth-child(4) {
        &::before,
        &::after {
          animation-delay: 0.6s;
        }
      }
      &:nth-child(5) {
        &::before,
        &::after {
          animation-delay: 0.7s;
        }
      }
      &:nth-child(6) {
        &::before,
        &::after {
          animation-delay: 0.8s;
        }
      }
      &:nth-child(7) {
        &::before,
        &::after {
          animation-delay: 0.9s;
        }
      }
      &:nth-child(8) {
        &::before,
        &::after {
          animation-delay: 1s;
        }
      }

      &::before {
        animation: max-height 0.7s cubic-bezier(0.61, 1, 0.88, 1) 1 normal both;
        color: var(--red);
      }

      &::after {
        animation: max-width 1.2s cubic-bezier(0.61, 1, 0.88, 1) 1 normal both;
        color: var(--navy);
      }

      &::before,
      &::after {
        content: attr(data-text);
        left: 0;
        overflow: hidden;
        position: absolute;
        speak: none;
      }
    }
  }

  @keyframes max-width {
    from {
      max-width: 0;
    }

    to {
      max-width: 100%;
    }
  }

  @keyframes max-height {
    from {
      max-height: 0;
    }

    to {
      max-height: 100%;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 630px;

    margin-top: ac(-6px, 0px);
  }

  &__text {
    max-width: 576px;
    font-size: ac(34px, 32px);
    font-weight: 500;
    line-height: 1.2;
    color: var(--blue);
  }

  &__subtitle {
    min-height: 130px;
    margin-top: ac(25px, 15px);
    font-size: ac(22px, 17px);
    line-height: ac(32px, 27px);

    font-family: var(--font-second);
    font-weight: 400;
    transition: 0.5s ease;

    .typed-cursor {
      display: inline-block;
      font-family: serif;
      font-weight: 400;
      font-size: 1.2em;
      transform: translateX(2px) scaleX(0.5);
    }
  }

  @mixin full {
    &__title {
      margin-right: 140px;
    }

    &__text {
      font-size: 66px;
    }

    &__subtitle {
      margin-top: 30px;
      font-size: 24px;
      line-height: 36px;
      min-height: 145px;
    }
  }

  @mixin md {
    &__title {
      margin-right: ac(87px, 20px, 768, 1440);
    }

    &__subtitle {
      min-height: 210px;
    }
  }

  @mixin sm {
    &__wrap {
      flex-direction: column;
    }

    &__col {
      margin-top: 20px;
    }

    &__subtitle {
      max-width: 100%;
      min-height: 140px;
    }
  }

  @mixin xxs {
    &__text {
      font-size: 32px;
    }

    &__subtitle {
      min-height: 190px;
    }
  }
}
