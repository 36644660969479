.tabs {
  padding-top: ac(120px, 60px);
}

.tabs__top-wrap {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;

  margin-bottom: ac(45px, 25px);
}

.tabs__title {
  max-width: 955px;

  color: var(--blue);

  font-size: ac(46px, 30px);
  font-weight: 600;
  line-height: 1.3;
}

.tabs__logo {
  width: ac(184px, 120px);
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.tabs__tab-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  margin-bottom: ac(60px, 25px);

  @mixin media 901 {
    flex-direction: column;
    gap: 20px;

    margin-bottom: 10px;
  }
}

.tab-slider__nav {
  display: flex;
  align-items: center;
  gap: ac(16px, 10px);

  margin-left: auto;
}

.tabs__tab-list {
  display: flex;

  li {
    padding-left: 0;
  }
}

.tabs__tab {
  position: relative;

  &:not(:last-child) {
    z-index: 1;

    margin-right: ac(-85px, -50px);

    button {
      box-shadow: ac(16px, 10px) 0 var(--white);
    }
  }

  &:not(:first-child) {
    button {
      padding-left: ac(131px, 70px);
    }
  }

  &.active {
    button {
      background: var(--red);
      color: var(--white);

      &:hover {
        color: var(--navy);
      }
    }
  }

  button {
    position: relative;

    padding: ac(16px, 12px) ac(30px, 20px);
    border-radius: 999px;

    color: var(--blue);
    background: var(--gray);

    font-size: ac(24px, 18px);
    font-weight: 500;

    transition: all ease 250ms;

    &:hover {
      color: var(--red);
    }
  }
}

.tabs__tab-content {
  display: flex;
  gap: ac(30px, 15px);
  /* padding: 10px; */
  margin: -10px;

  @mixin media 769 {
    flex-direction: column-reverse;
  }
}

.tabs__tab-text-wrap {
  display: flex;
  flex-direction: column;
  gap: ac(26px, 16px);

  width: 23%;

  @mixin media 769 {
    width: 100%;
  }
}

.tabs__tab-text {
  font-size: ac(16px, 14px);
  font-weight: 300;
  line-height: 2;

  @mixin media 769 {
    text-align: center;
  }
}

.tabs__tab-text-big {
  font-size: ac(24px, 18px);
  font-weight: 500;
  line-height: 1.2;

  @mixin media 769 {
    text-align: center;
  }
}

.tab-card {
  height: 100%;

  padding: ac(34px, 15px) ac(30px, 15px);
  border-radius: ac(12px, 10px);

  background: var(--gray);

  box-shadow: 0px 5px 5px 0px #00000029;
}

.tab-card__title {
  margin-bottom: ac(20px, 12px);

  color: var(--blue);

  font-size: ac(24px, 18px);
  font-weight: 500;
  line-height: 1.2;
}

.tab-card__description {
  * {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.725;
  }
}

.tab-slider {
  overflow: hidden;

  width: 100%;

  padding: 10px;

  .swiper-slide {
    height: auto;
  }
}
