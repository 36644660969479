.way {
  position: relative;
  margin-top: ac(120px, 70px);

  //padding: ac(570px, 370px) 0 120px;
  padding: 0 0 120px;

  border-radius: 25px 25px 0 0;

  color: var(--navy);

  &__decor {
    position: absolute;
    left: 0px;
    bottom: ac(0px, 173px);
    width: ac(414px, 140px);
    height: ac(381px, 120px);
  }

  &__wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  &__title-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 auto;
    max-width: ac(560px, 430px, 1024, 1440);
    margin-right: ac(137px, 25px, 1024, 1440);

    h2 {
      margin-bottom: 20px;
    }
  }

  &__logo {
    width: ac(220px, 180px, 1024, 1440);
    height: ac(184px, 144px, 1024, 1440);

    img {
      object-fit: contain;
    }
  }

  &__col {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    width: calc(50% - ac(10px, 5px, 1024, 1440));
    padding: ac(30px, 20px, 1024, 1440);
    background-color: var(--blue);
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
    transition: 0.3s ease;
    cursor: pointer;

    .btn {
      width: 100%;
      max-width: 100%;
      margin-top: auto;
      border-radius: 8px;
    }

    &--red {
      background-color: var(--red);
    }

    &:hover {
      background-color: var(--navy);
      color: var(--blue);

      .btn {
        color: var(--navy);
        background-color: var(--white);
      }

      .way__title {
        color: var(--blue);
      }

      ul {
        li {
          color: var(--blue);

          &::before {
            background-color: var(--blue);
          }
        }
      }

      &.way__card--red {
        color: var(--red);

        .way__title {
          color: var(--red);
        }

        ul {
          li {
            color: var(--red);

            &::before {
              background-color: var(--red);
            }
          }
        }
      }

      .way__icon {
        img {
          &:nth-child(1) {
            opacity: 0;
          }
          &:nth-child(2) {
            opacity: 1;
          }
        }
      }
    }
  }

  &__descr {
    margin-bottom: 20px;
  }

  &__icon {
    position: relative;

    transition: 0.3s ease;

    img {
      position: absolute;
      object-fit: contain;
      transition: 0.3s ease;

      &:nth-child(2) {
        opacity: 0;
      }
    }

    &--bye {
      width: 74px;
      height: 99px;
    }

    &--hello {
      width: 100px;
      height: 100px;
    }
  }

  &__title {
    margin-top: ac(35px, 20px, 1024, 1440);
    font-size: ac(20px, 18px);
    line-height: ac(38px, 29px);
    font-weight: 600;

    color: var(--white);

    transition: color ease 250ms;
  }

  ul {
    margin-top: 19px;
  }

  ul > li {
    padding-left: ac(24px, 14px);

    font-size: 16px;
    font-weight: 300;
    line-height: 1.625;

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    &::before {
      left: ac(10px, 0px);
    }
  }

  @mixin full {

    &__title-box {
      margin-right: 200px;
      max-width: 499px;
    }

    &__logo {
      width: 250px;
      height: 214px;
    }

    &__card {
      width: calc(50% - 30px);
      padding: 55px 65px;
    }

    &__icon--bye {
      width: 89px;
      height: 114px;
    }

    &__icon--hello {
      width: 115px;
      height: 115px;
    }

    &__title {
      margin-top: 50px;
      font-size: 28px;
      line-height: 42px;
    }
  }

  @mixin md {
    &__wrap {
      flex-direction: column;
    }

    &__title-box {
      max-width: 100%;
      flex-direction: row;
      margin-right: 0;
    }

    &__logo {
      flex: 0 0 auto;
      height: 124px;
      width: 140px;
    }

    &__card {
      width: calc(50% - ac(15px, 7px, 768, 1024));
      padding: ac(40px, 15px, 568, 1024) ac(50px, 20px, 568, 1024);
    }
  }

  @mixin xs {
    &__col {
      flex-direction: column;
      margin-top: 0;
    }

    &__title-box {
      h2 {
        margin-bottom: 35px;
      }
    }

    &__card {
      width: 100%;
      display: flex;
      padding: 38px 36px 35px;

      &:not(:last-child) {
        margin-bottom: 25px;
      }

      @mixin media 415 {
        flex-direction: column;
      }
    }

    &__icon {
      flex: 0 0 auto;
    }

    .way__icon--bye {
      width: 61px;
      height: 81px;
      margin-right: 21px;
    }

    .way__icon--hello {
      height: 81px;
      width: 81px;
      margin-right: 13px;
    }

    &__logo {
      display: none;
    }
  }
}
