.features {
  position: relative;
  padding: ac(120px, 60px) 0 0;
  background: transparent;

  &.empty {
    padding: ac(130px, 40px) 0 ac(130px, 40px);
  }

  &__decor {
    position: absolute;
    bottom: 0;
    left: 0;
    width: ac(799px, 280px);
    height: ac(799px, 280px);

    img {
      object-fit: contain;
    }
  }

  &__wrap {
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 460px;
    padding: ac(38px, 15px, 768, 1440) ac(40px, 15px, 768, 1440);
    border: 1px solid rgba(11, 3, 49, 0.6);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    background-color: var(--gray);
    border-radius: 8px;
    perspective: 1000px;
    transform-style: preserve-3d;
    cursor: pointer;

    .btn {
      width: 100%;
      max-width: 100%;
      margin-top: auto;
      border-radius: 8px;
    }

    &:hover {
      .features__icon {
        transform: translateZ(50px);
      }

      .features__title,
      .features__subtitle {
        transform: translateZ(40px);
      }
    }

    &:not(:last-child) {
      margin-right: ac(20px, 15px, 768, 1440);
    }

    &:nth-child(2) {
      background-color: var(--blue);
      color: var(--white);
      border-color: var(--blue);
    }

    &:nth-child(3) {
      background-color: var(--red);
      border-color: var(--red);
      color: var(--white);
    }
  }

  &__icon {
    width: ac(80px, 60px);
    height: ac(80px, 60px);
    transition: 0.3s ease;

    img {
      object-fit: contain;
      object-position: bottom;
    }
  }

  &__title {
    margin-top: ac(33px, 20px, 320);
    transition: 0.3s ease;
    max-width: 13ch;
    font-weight: 600;
  }

  &__subtitle {
    margin-bottom: 20px;
    margin-top: ac(23px, 5px, 320);

    &, p {
      font-weight: 300;
      transition: 0.3s ease;
    }

  }

  @mixin full {
    &__decor {
      width: 950px;
      height: 950px;
    }

    &__item {
      padding: 40px 45px;

      &:not(:last-child) {
        margin-right: 100px;
      }
    }

    &__icon {
      width: 90px;
      height: 90px;
    }
  }

  @mixin sm {
    &__wrap {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      flex-flow: row wrap;
      align-items: center;

      &:not(:last-child) {
        margin-right: 0;
      }
      padding: ac(30px, 10px, 375, 768) ac(40px, 10px, 375, 768);

      &:not(:first-child) {
        margin-top: 50px;
      }
    }

    &__title {
      max-width: 100%;
    }


    &__icon {
      margin-right: 15px;

      img {
        object-position: center;
      }
    }
  }

  @mixin xxs {
    &__icon {
      margin-right: 10px;
    }
  }
}
