.form-work {
  position: relative;
  margin-top: ac(181px, 92px);

  &__decor {
    position: absolute;
    top: ac(-55px, -108px);
    left: 0;
    width: ac(360px, 190px);
    height: ac(360px, 190px);
    z-index: -1;
  }

  &__wrap {
    padding: 0 ac(33px, 23px, 1024, 1440) 0 ac(106px, 70px, 1024, 1440);
  }

  &__title {
    font-size: ac(46px, 22px);
    line-height: ac(60px, 38px);

    span {
      display: inline-block;
      margin-top: ac(3px, 0px);
      font-size: ac(66px, 46px);
      line-height: 1em;
      text-transform: none;
    }
  }

  &__subtitle {
    margin-top: ac(54px, 12px);
    margin-left: ac(2px, 0px);
    font-size: ac(26px, 19px);
    line-height: ac(38px, 29px);
    font-weight: 600;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: ac(20px, 10px);
    /* padding-left: ac(3px, 0px); */

    &--sec {
      flex-direction: column;
      max-width: 500px;
      width: 100%;
      margin: ac(100px, 50px) auto ac(70px, 40px);

      h3 {
        text-align: center;
      }

      .form-work__label {
        flex: 0 0 auto;
        margin-right: 40px;
      }

      .form-work__input {
        width: 100%;
        padding-left: ac(25px, 0px);
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 27px;
  }

  &__label {
    margin-right: 15px;
    font-size: ac(22px, 17px);
    line-height: ac(32px, 27px);
    font-weight: 500;
  }

  &__input {
    width: ac(320px, 220px, 1024, 1440);
    padding: 10px 0;
    border-bottom: 1px solid var(--gainsboro);
    border-radius: 0;

    position: relative;
    top: 5px;
    padding-left: 7px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: var(--black) !important;
    }

    &:focus,
    &:valid {
      border-color: var(--gainsboro);
    }

    &,
    &::placeholder {
      font-family: var(--font-main);
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
    }

    &::placeholder {
      color: var(--black-o);
    }
  }

  &__select {
    position: relative;
    top: 5px;
    padding-left: 7px;
  }

  &__btn-box {
    padding-top: ac(13px, 3px);
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__btn {
    min-width: 120px;
  }

  @mixin full {
    padding-top: 150px;

    &__wrap {
      padding: 0 140px 0 170px;
    }

    &__title {
      font-size: 56px;
      line-height: 70px;

      span {
        font-size: 86px;
        line-height: 1em;
      }
    }

    &__subtitle {
      margin-top: 60px;
      font-size: 28px;
      line-height: 42px;
    }

    &__form {
      margin-top: 30px;
    }

    &__item {
      margin-bottom: 32px;
    }

    &__label {
      margin-right: 35px;
      font-size: 24px;
      line-height: 36px;
    }

    &__input {
      width: 400px;
      padding: 12px 0;

      &,
      &::placeholder {
        font-size: 18px;
        line-height: 28px;
      }
    }

    &__btn {
      min-width: 180px;
    }
  }

  @mixin md {
    &__wrap {
      padding: 0 ac(150px, 0px, 568, 1024);
    }

    &__item {
      width: 100%;
    }

    &__form--sec {
      .form-work__label {
        min-width: unset;
        margin-right: ac(35px, 25px, 568, 1024);
      }
    }

    &__label {
      min-width: 210px;
      margin-right: ac(35px, 0px, 568, 1024);
    }

    &__input,
    .nice-select {
      width: 100%;
    }
  }

  @mixin xs {
    &__title {
      font-weight: 700;

      span {
        font-weight: 800;
      }
    }
    &__btn-box {
      justify-content: center;
    }
    &__form--sec {
      .form-work__label {
        margin-right: 0;
      }
    }

    &__label {
      width: 100%;
    }

    &__input {
      padding: 7px 0;

      &,
      &::placeholder {
        font-size: 15px;
        line-height: 25px;
      }
    }

    &__item {
      flex-direction: column;
    }


  }

  @mixin xxs {
    &__title {
      span {
        font-size: 36px;
      }
    }
  }
}
