.care {
  position: relative;
  margin-top: ac(157px, 80px);
  z-index: 2;

  .ui-widget.ui-widget-content {
    padding: 0;
    border: 0;
    border-radius: 0 0 25px 25px;
  }

  .ui-tabs .ui-tabs-nav .ui-tabs-anchor {
    padding: 0;
    cursor: pointer;
    font-size: ac(26px, 17px);
    line-height: ac(38px, 25px);
    font-weight: 600;
    color: var(--black);
    letter-spacing: 0.1px;
  }

  .ui-tabs .ui-tabs-nav {
    padding: 0;
    background: none;
    margin-bottom: ac(60px, 31px);
  }

  .ui-tabs .ui-tabs-nav li {
    margin: 0 57px 0 0;
    padding-bottom: 22px;
  }

  .ui-tabs .ui-tabs-panel {
    background-color: var(--white);
    display: flex;
    padding: 0;
    box-shadow: none;
  }

  &__title-box {
    flex: 0 0 auto;
    width: 290px;
  }

  &__subtitle {
    margin-top: 16px;
  }

  &__text {
    margin-top: 17px;

    p {
      font-size: ac(22px, 17px);
      line-height: ac(32px, 27px);
      font-weight: 500;
    }
  }

  &__tabs {
    border-radius: 0;
    border: 0;

    .ui-state-active,
    .ui-widget-content .ui-state-active,
    .ui-widget-header .ui-state-active,
    a.ui-button:active,
    .ui-button:active,
    .ui-button.ui-state-active:hover {
      background: none;
      border: 0;
    }

    .ui-state-default,
    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default,
    .ui-button,
    html .ui-button.ui-state-disabled:hover,
    html .ui-button.ui-state-disabled:active {
      background: none;
      margin: 0;
      border: 0;
    }

    .ui-tabs-tab {
      &::before {
        display: none;
      }
    }

    .ui-tab {
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0%;
        height: 2px;
        background-color: black;
        transition: 0.3s ease;
      }

      &:hover {
        &:not(.ui-tabs-active) {
          .ui-tabs-anchor {
            color: var(--red);
          }
        }
      }
    }

    .ui-tabs-active {
      a {
        font-size: ac(26px, 17px);
        line-height: ac(38px, 25px);
        font-weight: 600;
        color: var(--black);
      }

      &::after {
        width: 100%;
      }
    }
  }

  &__tab {
    margin: 0;
    padding: 0;
    background: none;
  }

  &__content {
    border-radius: 0 0 25px 25px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
  }

  &__nav-box {
    position: absolute;
    top: -8px;
    right: 0;
    display: flex;
  }

  &__btn-prev {
    margin-right: 10px;
  }

  &__slider-box {
    width: 100%;
    display: flex;
    overflow: hidden;
  }

  &__slider {
    width: 100% !important;
    overflow: hidden;
  }

  &__item {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 12px;
    background-color: var(--gray);
    padding: 20px;
  }

  &__head {
    display: flex;
  }

  &__icon {
    flex: 0 0 auto;
    width: ac(54px, 48px);
    height: ac(54px, 48px);
    margin-right: ac(41px, 20px);
    padding: 10px;
    border-radius: 8px;
    background-color: var(--gainsboro);

    display: none;

    img {
      object-fit: contain;
    }
  }

  &__benefits {
    font-size: ac(26px, 19px);
    line-height: ac(38px, 29px);
    font-weight: 600;
    color: var(--blue);
    transition: 0.3s ease;
  }

  &__descr {
    padding-top: 10px;
    //padding: 0 0 ac(7px, 0px) ac(95px, 68px);
  }

  @mixin full {
    .ui-tabs .ui-tabs-panel {
      padding: 40px 65px 60px;
    }

    .ui-tabs .ui-tabs-nav .ui-tabs-anchor {
      font-size: 28px;
      line-height: 42px;
    }

    &__title-box {
      width: 340px;
      margin-right: 20px;
      margin-left: 0px;
    }

    &__text {
      p {
        font-size: 24px;
        line-height: 36px;
      }
    }

    &__icon {
      width: 60px;
      height: 60px;
      margin-right: 75px;
    }

    &__benefits {
      font-size: 28px;
      line-height: 42px;
    }

    &__slide {
      max-width: 360px;
    }

    &__item {
      padding: 0 0 0 40px;
    }
  }

  @mixin md {
    .ui-tabs .ui-tabs-panel {
      flex-direction: column;
    }

    &__title-box {
      width: calc(100% - 55px);
      margin-bottom: 30px;
    }
  }

  @mixin sm {
    padding-bottom: 71px;

    &__nav-box {
      width: 100%;
      justify-content: space-between;
      top: unset;
      bottom: -71px;
    }
  }

  @mixin xs {
    &__icon {
      border-radius: 5px;
    }

    .ui-tabs .ui-tabs-nav {
      display: flex;
      overflow: hidden;
      border: 2px solid var(--smokey);
    }

    .ui-tabs-tab {
      background-color: var(--seashell);
      position: relative;

      &:not(:first-of-type) {
        border-radius: 0;
      }
    }

    .ui-tabs .ui-tabs-nav li {
      width: 50%;
      margin-right: 0;
      padding-bottom: 0;
    }

    .ui-tabs .ui-tabs-nav .ui-tabs-anchor {
      width: 100%;
      padding: 12px 19px;
      white-space: normal;
      display: flex;
      align-items: center;
      font-weight: 600;
      position: relative;
      color: var(--black);
      z-index: 1;
    }

    .ui-tab {
      &::after {
        left: 0;
        height: 100%;
        background-color: var(--red);
      }

      &:nth-of-type(1) {
        &::after {
          right: 0;
          left: unset;
        }
      }
    }

    .ui-tabs-tab.ui-tabs-active {
      .ui-tabs-anchor {
        color: var(--black);
      }
    }

    &__title-box {
      width: calc(100% - 80px);
      margin-left: 40px;
    }

    &__descr {
      margin-top: -3px;
    }

    &__item {
      border-left: none;
      padding: 0 40px;
    }

    &__content {
      border-radius: 0 0 15px 15px;
    }

    &__slide {
      max-width: unset;
    }
  }
}
