.about {
  position: relative;
  margin-top: ac(128px, 81px);

  &__decor {
    position: absolute;
    right: 0;
    top: 0;
    width: ac(559px, 280px);
    height: ac(559px, 280px);
    overflow: hidden;

    img {
      object-fit: contain;
    }
  }

  &__title {
    max-width: 21ch;
  }

  .ui-widget.ui-widget-content {
    padding: 0;
    border: 0;
    border-radius: 0;
    margin-top: 36px;
  }

  .ui-tabs .ui-tabs-nav .ui-tabs-anchor {
    padding: 0;
    cursor: pointer;
    font-size: ac(22px, 17px);
    line-height: ac(32px, 25px);
    font-weight: 500;
    color: var(--black);

    letter-spacing: 0.04px;
  }

  .ui-tabs .ui-tabs-nav {
    margin-bottom: ac(40px, 31px);
    padding: 0;
    background: none;
    display: flex;
  }

  .ui-tabs .ui-tabs-nav li {
    margin: 0 65px 0 0;
    padding-bottom: 20px;
  }

  .ui-tabs .ui-tabs-panel {
    padding: ac(20px, 40px) ac(80px, 0px) ac(54px, 40px) ac(40px, 0px);
    background-color: var(--white);
    display: flex;
  }

  .ui-state-active,
  .ui-widget-content .ui-state-active,
  .ui-widget-header .ui-state-active,
  a.ui-button:active,
  .ui-button:active,
  .ui-button.ui-state-active:hover {
    background: none;
    border: 0;
  }

  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default,
  .ui-button,
  html .ui-button.ui-state-disabled:hover,
  html .ui-button.ui-state-disabled:active {
    background: none;
    margin: 0;
    border: 0;
  }

  .ui-tabs {
    background: transparent;
  }

  .ui-tabs-nav {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid var(--gainsboro);
  }

  .ui-tabs-tab {
    &::before {
      display: none;
    }
  }

  .ui-tab {
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0%;
      height: 2px;
      background-color: black;
      transition: 0.3s ease;
    }

    &:hover {
      &:not(.ui-tabs-active) {
        .ui-tabs-anchor {
          color: var(--red);
        }
      }
    }
  }

  .ui-tabs-active {
    a {
      font-size: ac(22px, 17px);
      line-height: ac(32px, 25px);
      font-weight: 500;
      color: var(--black);
    }

    &::after {
      width: 100%;
    }
  }

  &__content-box {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 100vw;
      height: calc(100% + 200px);
      border-radius: 25px 25px 0 0;
      background-color: var(--seashell);
      z-index: -1;
    }
  }

  &__content {
    border-radius: 0 0 25px 25px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
  }

  &__nav-box {
    position: absolute;
    top: -100px;
    right: 0;
    display: flex;
  }

  &__btn-prev {
    margin-right: 10px;
  }

  &__slider-box {
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: ac(40px, 0px);
      background-color: var(--white);
      z-index: 2;
    }
  }

  &__slider {
    width: 100% !important;
  }

  &__slide {
    height: 100%;
  }

  &__card {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 0 0 52px;
    border-left: 3px solid var(--black);
  }

  &__head {
    width: 15ch;
    font-size: ac(26px, 19px);
    line-height: ac(38px, 29px);
    font-weight: 600;
  }

  &__text {
    margin-top: ac(26px, 25px);
    margin-bottom: ac(52px, 16px);
  }

  &__name {
    margin-top: auto;
    font-weight: 500;
  }

  &__position {
    font-size: 14px;
  }

  @mixin full {
    margin-top: 140px;

    &__decor {
      width: 650px;
      height: 650px;
    }

    .about .ui-widget.ui-widget-content {
      margin-top: 40px;
    }

    .ui-tabs .ui-tabs-nav .ui-tabs-anchor {
      font-size: 24px;
      line-height: 36px;
    }

    .ui-tabs .ui-tabs-panel {
      padding: 40px 65px 60px;
    }

    &__card {
      padding: 0 0 0 75px;
    }

    &__head {
      font-size: 28px;
      line-height: 42px;
    }

    &__text {
      margin-top: 30px;
      margin-bottom: 55px;
    }

    &__position {
      font-size: 16px;
    }
  }

  @mixin md {
    &__decor {
      top: ac(280px, 368px, 375, 1024);
    }
  }

  @mixin sm {
    padding-bottom: 71px;

    &__nav-box {
      width: 100%;
      justify-content: space-between;
      top: unset;
      bottom: -71px;
    }
  }

  @mixin xs {
    &__content {
      border-radius: 0 0 15px 15px;
    }

    .ui-tabs .ui-tabs-nav {
      overflow: hidden;
      border: 2px solid var(--smokey);
      background-color: white;
    }

    .ui-tabs-tab {
      &:not(:first-of-type) {
        border-radius: 0;
      }
    }

    .ui-tabs .ui-tabs-nav li {
      width: 50%;
      margin-right: 0;
      padding-bottom: 0;
    }

    .ui-tabs .ui-tabs-nav .ui-tabs-anchor {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 12px 19px;
      white-space: normal;
      font-weight: 600;
      position: relative;
      color: var(--black);
      z-index: 1;
    }
    .ui-tab {
      &::after {
        left: 0;
        height: 100%;
        background-color: var(--red);
      }

      &:nth-of-type(1) {
        &::after {
          right: 0;
          left: unset;
        }
      }
    }
    .ui-tabs-tab.ui-tabs-active {
      .ui-tabs-anchor {
        color: var(--black);
      }
    }

    .ui-tabs .ui-tabs-panel {
      padding-left: 0;
      padding-right: 0;
    }

    &__card {
      border-left: none;
      padding: 0 40px;
    }
  }
}
